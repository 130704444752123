<template>
<div class="container">
    <div class="d-flex justify-content-center h-100 p-4">
        <slot />
    </div>
</div>
</template>

<script>
export default {
    name: 'AuthorizationLayout',
    components: {}
};
</script>
