<template>
    <!-- Main navbar -->
	<div class="navbar navbar-expand-md navbar-dark bg-indigo navbar-static fixed-top">
		<div class="navbar-brand d-flex pb-2 pt-2">
			<a href="index.html" class="align-items-center color-white d-flex d-inline-block">
				<!-- <img src="/assets/global_assets/images/logo_light.png" alt=""> -->
				<i class="icon-database txt-logo"></i>
				<span class="ml-2 text-white" style="font-size: 14px;">Omni Center</span>
			</a>
			
		</div>

		<div class="d-md-none">
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
				<i class="icon-tree5"></i>
			</button>
			<button class="navbar-toggler sidebar-mobile-main-toggle" type="button">
				<i class="icon-paragraph-justify3"></i>
			</button>
		</div>

		<div class="collapse navbar-collapse" id="navbar-mobile">
			<ul class="navbar-nav">
				<li class="nav-item">
					<a href="#" class="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block">
						<i class="icon-paragraph-justify3"></i>
					</a>
				</li>
			</ul>

			<ul class="navbar-nav ml-md-auto">
				<li class="nav-item">
					<a href="javascript:void(0);" class="navbar-nav-link" @click="Logout()">
						<i class="icon-switch2"></i>
						<span class="d-md-none ml-2">Logout</span>
					</a>
				</li>
			</ul>
		</div>
	</div>
	<!-- /main navbar -->
</template>

<script>
export default {
    name: "TopMenu",
    methods: {
		Logout() {
			this.$store.dispatch('auth/logout')
			this.$router.push('/login').catch(e => {})
		}
	}
};
</script>
<style scoped>
	.txt-logo {
		font-size: 20px;
		color: aliceblue;
	}
</style>
