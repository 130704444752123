<template>
    <section>

    <!-- Main navbar -->
    <top-menu></top-menu>

    <!-- Page content -->
    <div class="page-content">
        <!-- Main sidebar -->
        <left-menu></left-menu>
        <div class="content-wrapper">
            <header-content></header-content>
            <div class="content">
                <slot />
            </div>
        </div>
    </div>
    <!-- /page content -->
    </section>
</template>

<script>
 import TopMenu from './admin/TopMenu'
 import LeftMenu from './admin/LeftMenu'
 import HeaderContent from './admin/HeaderContent'

export default {
    name: "AdminLayout",
    components: {
         TopMenu,
         LeftMenu,
         HeaderContent
    }
}
</script>
