export default {
    mutations: {
        vueTableFormatJson(restData) {
            let jsonData = restData.data;
            let data = [];
            for (let [k, v] of Object.entries(jsonData)) {
                switch (k) {
                    case 'links':
                        let link = []
                        for (let [k_link, v_link] of Object.entries(v)) {
                            link[k_link + '_page_url'] = v_link
                        }
                        data = {...data, ...link };
                        break;
                    case 'meta':
                        data = {...data, ...v };
                        break;
                    case 'data':
                        data = {...data, ... { data: v } };
                        break;
                    case 'status':
                        data = { status: v }
                        break;
                    default:
                        data[k] = {...data[k], ...v };
                }
            }
            return data;
        },
        syntaxHighlight(json) {
            if(json == undefined) { return '' }
            json = JSON.stringify(json, undefined, 4)
            json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
            return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
                var cls = 'number';
                if (/^"/.test(match)) {
                    if (/:$/.test(match)) {
                        cls = 'key';
                    } else {
                        cls = 'string';
                    }
                } else if (/true|false/.test(match)) {
                    cls = 'boolean';
                } else if (/null/.test(match)) {
                    cls = 'null';
                }
                return '<span class="' + cls + '">' + match + '</span>';
            })
        },
        OptionJsonFormat(restData) {
            let data = []
            for (let [k, v] of Object.entries(restData.data.data)){
                data[k] = {
                    id: v._id,
                    text: v.shop
                }
            }
            return data
        },
        getImage(file_name) {
            var image = require('/public/assets/images/no-image.png');
            try {
                image = '<img src="' + this.getImageSrc(file_name) + '" width="24">';
            } catch (error) { }
            return image;
        }
    },
    ranges(){
        let today = new Date()
        today.setHours(0, 0, 0, 0)
        let todayEnd = new Date()
        todayEnd.setHours(11, 59, 59, 999);

        let yesterdayStart = new Date()
        yesterdayStart.setDate(today.getDate() - 1)
        yesterdayStart.setHours(0, 0, 0, 0);

        let yesterdayEnd = new Date()
        yesterdayEnd.setDate(today.getDate() - 1)
        yesterdayEnd.setHours(11, 59, 59, 999);

        let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999);
        
        const _default_ranges ={
            'Today': [today, todayEnd],
            'Yesterday': [yesterdayStart, yesterdayEnd],
            'This month': [thisMonthStart, thisMonthEnd],
            'This year': [
                new Date(today.getFullYear(), 0, 1),
                new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999)
            ],
            'Last month': [
                new Date(today.getFullYear(), today.getMonth() - 1, 1),
                new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999)
            ],
        }

        let _ranges = [];
        for (const [key, value] of Object.entries(_default_ranges)) {
            _ranges[key] = value;
        }
        return Object.assign({}, _ranges);

    },
    monthNames() {
        return [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", 
            "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].slice(0, 12);
    },
    daysOfWeek(){
        return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].slice(0, 7).map((function(t) {
            return t.substring(0, 2)
        }))
    },
}

