import axios from 'axios';
import Cookies from 'js-cookie'

const BASE_URI = process.env.VUE_APP_OMNI_CENTER_API

class AuthService {
    login(user) {
        let data = {
            'username': user.username,
            'password': user.password
        }
        return axios.post(BASE_URI + '/auth/login', data).then(response => {
            if (response.status) {
                Cookies.set('Token', response.data.access_token)
                return response.data.user_info
            }
        })
    }
    logout() {
        Cookies.remove('Token')
        location.href = '/login'
    }
}

export default new AuthService()
