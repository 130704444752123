import Vue          from 'vue'
import App          from './App.vue'
import router       from './router';

import Vuelidate    from 'vuelidate';
import store        from '@/store/main.js';
import Cookies      from "js-cookie"
import axios        from 'axios'

import VueToastr from "vue-toastr";
import VueApexCharts from 'vue-apexcharts'
import VueRecaptcha from 'vue-recaptcha'


import storeIndex   from './store/index';
import AuthService  from './services/auth.service'
import { auth }     from '@/store/auth.module'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

/************** Vue Use Third party ***********************/
Vue.component('apexchart', VueApexCharts)
Vue.component('vue-recaptcha', VueRecaptcha)

Vue.use(VueSweetalert2);
Vue.use(Vuelidate)
Vue.config.productionTip = false
Vue.prototype.$storeIndex = storeIndex

Vue.use(VueToastr, {
  defaultTimeout: 5000,
  defaultProgressBar: true,
  defaultPosition: "toast-top-right",
  defaultClickClose: true,
});

/************** Handle Router  ***************************/
router.beforeEach((to, from, next) => {
  Vue.prototype.$setLayout = to

  
  const publicPages = [ "/login" , "/register" ]
  const authRequired = publicPages.includes(to.path)
  const Token = Cookies.get("Token")
  

  /** If path current != path in array && token is not have --> go page login */
  if (!authRequired && !Token) {
    next({path: publicPages[0] })
  }else{
    next()
  }

  /** If path current == path in array && If Login is true --> go page default */
  if ( authRequired && Token ) {
    next({path: '/'})
  }
  
})

/************** Handle Axios *****************************/
axios.interceptors.request.use((request) => {
  return request
})

axios.interceptors.response.use(
  (response) => {
    if (response.status === 201) {
    }
    return response
  },
  (error) => {
    if (error.response != undefined && error.response.status == 401) {
      // store.dispatch('auth/logout')
      // router.push('/login').catch(err => {})
    }
    return Promise.reject(error);
  }
)

/************** Global prototype  *****************************/
Number.prototype.format = function(n, x) {
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
  return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
}

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
