<template>
  <transition name="page" mode="out-in">
    <component :is="layout" v-if="layout">
      <router-view />
    </component>
  </transition>
</template>

<script>
import Cookies      from "js-cookie"
import AdminLayout  from "@/components/layouts/AdminLayout";
import AuthorizationLayout from "@/components/layouts/AuthorizationLayout";
export default {
  name: "App",
  components: {
    AdminLayout,
    AuthorizationLayout
  },
  data() {
    return {
      layout: null,
      defaultLayout: "authorization-layout",
    }
  },
  watch: {
    /** is Login true use layout-admin */
    '$store.state.auth.status.loggedIn' : function(value) {
      if(!value) {
        this.layout = 'authorization-layout'
        document.body.classList.add('authorization-layout')
        document.body.classList.remove('admin-layout')
      }
    }
  },
  methods: {
    setLayout(route) {
      if (typeof route == "undefined" || this.layout != null) {
        this.layout = this.defaultLayout;
        document.body.classList.add(this.layout);
      } else {
        this.layout = route;
        document.body.classList.add(this.layout);
      }
    }
  },
  created() {
    this.setLayout(this.$setLayout.meta.layout)
    if( Cookies.get("Token") ) {
      this.$store.dispatch('auth/setLogin')
    }
  },
};
</script>

<style lang="scss">
@import '/public/assets/scss/custom.scss';
</style>
