<template>
    <!-- Page header -->
    <div class="page-header page-header-light">
        <div class="page-header-content header-elements-md-inline">
            <div class="page-title d-flex">
                <h4>
                    <i class="icon-arrow-left52 mr-2 pointer-cursor" @click="backToPrevPage"></i> 
                    <span class="font-weight-semibold">{{ title }}</span>
                </h4>
                <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
            </div>

        </div>

        <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
            <div class="d-flex">
                <div class="breadcrumb ">
                    <router-link to="/" class="breadcrumb-item"><span>Dashboard</span></router-link>
                    <router-link :to="breadcrumb.link || '#' " v-for="(breadcrumb, idx) in breadcrumbList" :key="idx" 
                        :class="[{'unlinked': !breadcrumb.link}, 'breadcrumb-item']"> 
                        {{ breadcrumb.name }}
                    </router-link>
                </div>
                <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
            </div>
        </div>
    </div>
    <!-- /page header -->
</template>

<script>
export default {
    name: 'HeaderContent',
    data() {
        return {
            title: '',
            item: this.$route,
            breadcrumbList: [],
            icon: ''
        }
    },
    watch: {
        '$route'(newRoute) {
            this.showTitle();
            this.updateList();
        },
    },
    methods: {
        backToPrevPage() {
            this.$router.push("/").catch(() => {})
            // const link = this.breadcrumbList[1].name.replace(" ", "-")
            // if (link !== undefined) {
            //     this.$router.push({ name: link });
            // } else {
            //     this.$router.push("/");
            // }
        },
        showTitle() {
            let suffix = this.$route.meta.suffix;
            if(suffix === undefined){
                suffix = ''
            }else{
                suffix = ' ' + suffix;
            }
            this.title = this.$route.meta.title
            document.title = this.$route.meta.title + suffix
        },
        updateList() {
            this.breadcrumbList = this.$route.meta.breadcrumb
        },
    },
    mounted() {
        this.updateList();
        this.showTitle();
    }
};
</script>

<style scoped>
.pointer-cursor {
    cursor: pointer;
}
</style>